import { nanoid } from 'nanoid';


export const headData = {
  title: 'David Ramirez', 
  lang: 'en', 
  description: 'Welcome to my website',
};

// HERO DATA
export const heroData = {
  title: 'Hello, im',
  name: 'David Ramirez',
  subtitle: 'Software Developer',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profilecv.jpg',
  paragraphOne: `I'm 29 years old, Argentine, I was born and lived all my life in Buenos Aires. I'm currently studying for a degree at UADE university. I work in logistics and at the same time, I'm developing websites and web applications that create solutions for my clients. `,
  paragraphTwo: `I handle HTML, CSS, JavaScript, as well as the MERN stack ecosystem (MongoDB, Express, React, and Node). I also developed applications using Redux. I know SQL and NoSQL databases using cloud providers like firebase or MongoDB Atlas. I know about the creation and management of REST APIs and unit and integration testing (using Jest and Cypress). Deploy applications using the REACT App or Next, and websites using the JAM stack with Gatsby in conjunction whit Netlify.`,
  paragraphThree: `Geek and Technophile, lover of Pop culture, I grew up watching Dragon Ball Z and Nivel X, as well as playing to the SEGA and the Nintendo 64. I was always attracted to software, initially believing that it was just a hobby for video games of a child raised in an apartment. But as I grew up I realized that I love the IT industry, particularly what involves development, it is still a game, a puzzle, in which the problem is sought to create the solution. When I am not working, I love spending time with my couple and my family, as well as playing video games (of course).`,
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'merntask.jpg',
    title: 'MERNtask',
    info: 'A web Aplication to organize projects',
    info2: 'Using the MERN stack, Create React App to Frontend, and MongoDB Atlas and Node to backend  ',
    url: 'https://merntasksproyect.netlify.app/',
    repo: 'https://github.com/DavidRamirez92/MERNTasks', 
  },
  {
    id: nanoid(),
    img: 'reactnode.jpg',
    title: 'REACT NODE SEND',
    info: 'A web aplication to share encripting files peer to peer. ',
    info2: 'Using Next in Frontend and Deploy en Versel using Node ',
    url: 'https://nodesend-client-2zv4xcqi6-davidramirez92.vercel.app/',
    repo: 'https://github.com/DavidRamirez92/nodeSendClient',
  },
  {
    id: nanoid(),
    img: 'nyg.jpg',
    title: 'N&G Servicios Integrales',
    info: 'Webpage from a Plumber Company in Argentina',
    info2: 'Using Gatsby and the JAM stack to develop and deploy in Netlify',
    url: 'https://nygserviciosintegrales.netlify.app/',
    repo: '',
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'davidramirez9210@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/david-ramirez-/',
    },

    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/DavidRamirez92',
    },
    
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/PopperDix',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
